// Newsletter Mailchimp JS designed by MoFe & WeKo
// 31.03.2021

import Cookie from 'js-cookie';

const getNewsletter = () => Cookie.get('MCPopupfemSense');

function openMailchimpModal() {
  const m = document.getElementById('mailchimp-modal');
  if (m) {
    m.classList.add('is-active');
  }
}

// MailChimp Validation Script
function serialize(form: HTMLFormElement) {
  return Array.from(form.elements)
    .map((field: any) => {
      if (
        !(
          !field.name ||
          field.disabled ||
          field.type === 'file' ||
          field.type === 'reset' ||
          field.type === 'submit' ||
          field.type === 'button'
        )
      ) {
        // Convert field data to a query string
        if (
          (field.type !== 'checkbox' && field.type !== 'radio') ||
          field.checked
        ) {
          // serialized = serialized + '&' + encodeURIComponent(field.name)
          // + '=' + encodeURIComponent(field.value);
          return `${encodeURIComponent(field.name)}=${encodeURIComponent(
            field.value,
          )}`;
        }
      }
      return '';
    })
    .join('&');
}

export const displayMailChimpStatus = (data: any) => {
  if (!data.result || !data.msg) return;

  // Get all status message content areas
  document.querySelectorAll('.mc-status').forEach((mcStatus) => {
    // Update our status message
    mcStatus.innerHTML = data.msg;
    // If error, add error class
    if (data.result === 'error') {
      mcStatus.classList.remove('success-message');
      mcStatus.classList.add('error-message');
      return;
    }
    // Otherwise, add success class
    mcStatus.classList.remove('error-message');
    mcStatus.classList.add('success-message');
  });
};

const submitMailChimpForm = (form: any) => {
  // Code goes here...
  let url = form.getAttribute('action');
  url = url?.replace('/post?u=', '/post-json?u=') ?? null;
  // const cc = displayMailChimpStatus;
  url = `${url}&${serialize(form)}&c=displayMailChimpStatus`;
  const script = window.document.createElement('script');
  script.src = url;
  // Insert script tag into the DOM (append to <head>)
  const ref = window.document.getElementsByTagName('script')[0];
  // @ts-ignore
  ref.parentNode.insertBefore(script, ref);
  // After the script is loaded (and executed), remove it
};

export const initMailchimp = (country?: string) => {
  const subscribe = getNewsletter();
  // replace subscribe with line above, & Timeout value set to 10000 instead of 1000
  // set cookie to 5h
  // const subscribe = 0;
  const mchimp = document.querySelector<HTMLElement>('#mailchimp-modal');

  const dcountries = mchimp?.dataset.countries;
  if (!country || !dcountries || !dcountries.includes(country)) {
    return;
  }

  if (!subscribe && !document.URL.includes('shop')) {

    setTimeout(() => {
      const m = document.getElementById('mailchimp-modal');
      if (m) {
        m.classList.add('is-active');
      }
    }, 3000);
    const btn = document.querySelector('.modal-close.mc');
    if (btn) {
      btn.addEventListener(
        'click',
        () => {
          // Cookie.set('MCPopupfemSense', 'yes', { expires: 7 });
          Cookie.set('MCPopupfemSense', 'yes');
        },
        { passive: true },
      );
    }
  }

  document.querySelectorAll<HTMLElement>('.subscribeNewsletter').forEach(
    (e) => {
      e.onclick = openMailchimpModal;
    },
    { passive: true },
  );

  document
    .querySelector<HTMLElement>('#mailchimp-modal')
    ?.addEventListener('submit', (event) => {
      if ((event.target as HTMLElement)?.classList.contains('validate')) {
        event.preventDefault();
        submitMailChimpForm(event.target);
      }
    });

  window.displayMailChimpStatus = displayMailChimpStatus;
};
